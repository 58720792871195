import {
  createStore as cStore,
  combineReducers,
  applyMiddleware,
  compose,
  AnyAction,
  Action,
  ReducersMapObject,
} from 'redux';

import thunk from 'redux-thunk';
import { useActions, useReduxState } from '../../hooks/useActions';

export function createStore<S, ActionTypes, A extends Action = AnyAction>(
  reducers: ReducersMapObject<S, A>,
  reducerActions: ActionTypes
) {
  const rootReducer = combineReducers({ ...reducers });

  type AppState = ReturnType<typeof rootReducer>;

  const composeEnhancers =
    ((window as any)[
      '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'
    ] as typeof compose) || compose;

  const store = cStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );

  function state<TSelected = unknown>(
    selector: (state: AppState) => TSelected
  ): TSelected {
    return useReduxState<TSelected>(selector);
  }

  function actions() {
    return useActions(reducerActions);
  }

  return { store, useActions: actions, useReduxState: state };
}
