import React, { CSSProperties, ReactNode, SVGAttributes } from 'react';
import styled from 'styled-components';

type SvgProps = Omit<
  SVGAttributes<SVGElement>,
  'color' | 'fontSize' | 'style'
> & {
  style?: Omit<CSSProperties, 'color' | 'fontSize'>;
};

export type SvgIconCreator = {
  content: ReactNode;
  defaultColor?: CSSProperties['color'];
  defaultProps?: SvgProps;
};

export type IconProps = {
  color?: CSSProperties['color'];
  fontSize?: CSSProperties['fontSize'];
  svgProps?: SvgProps;
};

export const createSvgIcon = <ColorfulIcon extends boolean = false>(
  props: ColorfulIcon extends true
    ? Omit<SvgIconCreator, 'defaultColor'>
    : SvgIconCreator
) => (
  iconProps: ColorfulIcon extends true ? Omit<IconProps, 'color'> : IconProps
) => (
  <SVGRoot
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props.defaultProps}
    {...iconProps.svgProps}
    color={
      (iconProps as IconProps).color ?? (props as SvgIconCreator).defaultColor
    }
    fontSize={iconProps.fontSize ?? 24}
  >
    {props.content}
  </SVGRoot>
);

const SVGRoot = styled.svg`
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
`;
