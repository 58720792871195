import React, {
  useRef,
  CSSProperties,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { CancelIcon, SearchIcon } from '../Icons/MainIcons';

export type Props = {
  placeholder?: string;
  value: string;
  disabled?: boolean;
  colors?: Partial<
    Record<
      | 'background'
      | 'backgroundHighlight'
      | 'font'
      | 'fontPlaceHolder'
      | 'primaryControl'
      | 'fontHighlight',
      CSSProperties['color']
    >
  >;
  scale?: number;
  flyoutPanel?: {
    show?: boolean;
    stayOn?: boolean;
    content: ReactNode;
  };
  closeOnEnterKey?:boolean;
  onChange?: (text: string) => void;
  onEnterKeyPressed?: () => void;
  onFocusChanged?: (isFocused: boolean) => void;
};

const SearchTextField = ({
  placeholder,
  onChange,
  onFocusChanged,
  onEnterKeyPressed,
  value,
  disabled,
  colors,
  scale,
  flyoutPanel,
  closeOnEnterKey
}: Props) => {
  const hasValue = value.length > 0;
  const inputRef = useRef<HTMLInputElement>(null);
  const combineColors = {
    background: '#e6e6e6',
    backgroundHighlight: '#e8f0fe',
    font: 'rgba(0, 0, 0, 0.95)',
    fontPlaceHolder: 'rgba(0, 0, 0, 0.55)',
    primaryControl: '#1a73e8',
    fontHighlight: '#1967d2',
    ...colors,
  };
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    onFocusChanged?.(focused);
  }, [focused]);

  return (
    <Container
      colors={combineColors}
      withText={hasValue && !disabled && !flyoutPanel}
      disabled={disabled}
      showFlyout={!!flyoutPanel && (focused || !!flyoutPanel?.show)}
      scale={scale || 1}
    >
      <div className="main">
        <div
          className="inputContainer"
          onMouseDown={(e) => {
            if (e.target !== inputRef.current) {
              e.preventDefault();
              inputRef.current?.focus();
            }
          }}
        >
          <SearchIcon />
          <div className="inputBase">
            <input
              ref={inputRef}
              type="text"
              placeholder={placeholder || 'Search'}
              aria-label="search"
              value={value}
              onChange={(e) => onChange?.(e.target.value)}
              disabled={disabled}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              onKeyDown={(e)=>{
                if( e.key === "Enter"){
                  onEnterKeyPressed?.();

                  closeOnEnterKey && setFocused(false);
                }
                else if( !focused){
                  setFocused(true);
                }
              }}
            />
          </div>
          {hasValue && (
            <CancelIcon
              svgProps={{
                'aria-label': 'cancel',
                onClick: () => !disabled && onChange?.(''),
                style: { cursor: disabled ? 'inherit' : 'pointer' },
              }}
            />
          )}
        </div>
        {flyoutPanel && (focused || !!flyoutPanel?.show) && (
          <div
            className="flyoutPanel"
            onMouseDown={(e) => e.preventDefault()}
            onMouseUp={() => {
              if (!flyoutPanel.stayOn)
                setTimeout(() => {
                  inputRef.current?.blur();
                });
            }}
          >
            {flyoutPanel.content}
          </div>
        )}
      </div>
    </Container>
  );
};

export default SearchTextField;

const Container = styled.div<{
  withText: boolean;
  showFlyout: boolean;
  disabled?: boolean;
  colors: NonNullable<Props['colors']>;
  scale: number;
}>`
  ${(props) => `
    transform: scale(${props.scale});
    transform-origin: left top;
    width: calc(100% / ${props.scale});
    height: calc(37px * ${props.scale});
  `}
  & .main {
    position: relative;
    ${(props) =>
      props.showFlyout &&
      `
        box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3), 0 1px 3px 1px rgba(65, 69, 73, 0.15);
        border-radius: 8px;
        background-color: #fff;
    `};

    & .flyoutPanel {
      border-top: 1px solid rgba(65, 69, 73, 0.15);
    }

    & .inputContainer {
      padding: 0.125em 0.25em;
      display: flex;
      align-items: center;
      background-color: ${(props) =>
        props.withText
          ? props.colors.backgroundHighlight
          : props.colors.background};
      border: 2px solid
        ${(props) =>
          props.showFlyout
            ? 'transparent'
            : props.withText
            ? props.colors.backgroundHighlight
            : props.colors.background};
      color: ${(props) =>
        props.withText
          ? props.colors.primaryControl
          : props.colors.fontPlaceHolder};
      &:focus-within {
        border: 2px solid
          ${(props) =>
            props.showFlyout ? 'transparent' : props.colors.primaryControl};
        background-color: white;
      }
      border-radius: 8px;
      & > div > input {
        color: ${(props) =>
          props.withText ? props.colors.fontHighlight : props.colors.font};
      }
      ${(props) => props.disabled && 'cursor: not-allowed;'}
    }

    & .inputBase {
      margin-left: 0.5em;
      flex: 1;
      & > input {
        font-size: 0.875rem;
        font-weight: 500;
        width: 100%;
        border: 0;
        height: 1.1876em;
        margin: 0;
        display: block;
        padding: 6px 0 7px;
        min-width: 0;
        background: none;
        box-sizing: content-box;
        animation-name: mui-auto-fill-cancel;
        letter-spacing: inherit;
        animation-duration: 10ms;
        outline: 0;
        &:focus {
          font-weight: 400;
          color: ${(props) => props.colors.font};
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
`;
