import React from 'react';
import styled, { keyframes } from "styled-components";
export type Props = {};

const LinearProgress = () => {
    
    return <Outer role="progressbar">
        <Inner1/>
        <Inner2/>
    </Outer>;
}

export default LinearProgress;

const Outer = styled.div`
        height: 4px;
    overflow: hidden;
    position: relative;
    background-color: rgb(182, 188, 226);
    
`;



const kframe1 = keyframes`
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
`;
const kframe2 = keyframes`
   0% {
     left: -200%;
     right: 100%;
   }
   60% {
     left: 107%;
     right: -8%;
   }
   100% {
     left: 107%;
     right: -8%;
   }
`;

const Inner1 = styled.div`
        width: auto;
    animation: ${kframe1} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    background-color: #3f51b5;
    top: 0;
    bottom: 0;
    position: absolute;
    transition: transform 0.2s linear;
    transform-origin: left;
`;

const Inner2 = styled.div`
    width: auto;
    animation: ${kframe2} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
    background-color: #3f51b5;
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    transition: transform 0.2s linear;
    transform-origin: left;
`;



// <style data-jss="" data-meta="MuiLinearProgress">
// .MuiLinearProgress-root {
//   height: 4px;
//   overflow: hidden;
//   position: relative;
// }
// @media print {
//   .MuiLinearProgress-root {
//     -webkit-print-color-adjust: exact;
//   }
// }
// .MuiLinearProgress-colorPrimary {
//   background-color: rgb(182, 188, 226);
// }
// .MuiLinearProgress-colorSecondary {
//   background-color: rgb(251, 158, 191);
// }
// .MuiLinearProgress-buffer {
//   background-color: transparent;
// }
// .MuiLinearProgress-query {
//   transform: rotate(180deg);
// }
// .MuiLinearProgress-dashed {
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   animation: MuiLinearProgress-keyframes-buffer 3s infinite linear;
//   margin-top: 0;
// }
// .MuiLinearProgress-dashedColorPrimary {
//   background-size: 10px 10px;
//   background-image: radial-gradient(rgb(182, 188, 226) 0%, rgb(182, 188, 226) 16%, transparent 42%);
//   background-position: 0 -23px;
// }
// .MuiLinearProgress-dashedColorSecondary {
//   background-size: 10px 10px;
//   background-image: radial-gradient(rgb(251, 158, 191) 0%, rgb(251, 158, 191) 16%, transparent 42%);
//   background-position: 0 -23px;
// }
// .MuiLinearProgress-bar {
//   top: 0;
//   left: 0;
//   width: 100%;
//   bottom: 0;
//   position: absolute;
//   transition: transform 0.2s linear;
//   transform-origin: left;
// }
// .MuiLinearProgress-barColorPrimary {
//   background-color: #3f51b5;
// }
// .MuiLinearProgress-barColorSecondary {
//   background-color: #f50057;
// }
// .MuiLinearProgress-bar1Indeterminate {
//   width: auto;
//   animation: MuiLinearProgress-keyframes-indeterminate1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
// }
// .MuiLinearProgress-bar1Determinate {
//   transition: transform .4s linear;
// }
// .MuiLinearProgress-bar1Buffer {
//   z-index: 1;
//   transition: transform .4s linear;
// }
// .MuiLinearProgress-bar2Indeterminate {
//   width: auto;
//   animation: MuiLinearProgress-keyframes-indeterminate2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
// }
// .MuiLinearProgress-bar2Buffer {
//   transition: transform .4s linear;
// }
// @-webkit-keyframes MuiLinearProgress-keyframes-indeterminate1 {
//   0% {
//     left: -35%;
//     right: 100%;
//   }
//   60% {
//     left: 100%;
//     right: -90%;
//   }
//   100% {
//     left: 100%;
//     right: -90%;
//   }
// }
// @-webkit-keyframes MuiLinearProgress-keyframes-indeterminate2 {
//   0% {
//     left: -200%;
//     right: 100%;
//   }
//   60% {
//     left: 107%;
//     right: -8%;
//   }
//   100% {
//     left: 107%;
//     right: -8%;
//   }
// }
// @-webkit-keyframes MuiLinearProgress-keyframes-buffer {
//   0% {
//     opacity: 1;
//     background-position: 0 -23px;
//   }
//   50% {
//     opacity: 0;
//     background-position: 0 -23px;
//   }
//   100% {
//     opacity: 1;
//     background-position: -200px -23px;
//   }
// }
// </style>