import React from "react";
import styled from "styled-components";

export type Props = {
  children: React.ReactNode;
  handleBackgroundClick?(): void;
};

const Dialog = ({ children, handleBackgroundClick }: Props) => {
  // return <DialogWrapper>{props.children}</DialogWrapper>;
  return (
    <BackDrop onClick={() => {handleBackgroundClick && handleBackgroundClick()} }>
      <DialogContainer>
        <DialogPaper
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </DialogPaper>
      </DialogContainer>
    </BackDrop>
  );
};

export default Dialog;

const BackDrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 3000;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const DialogContainer = styled.div`
  height: 100%;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DialogPaper = styled.div`
  margin: 32px;
  position: relative;
  overflow-y: auto;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  border-radius: 4px;
  min-width: 200px;
`;
