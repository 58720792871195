import React, { ReactNode, useState } from 'react';
import AppBarSimple from '../AppBarSimple';

import styled from 'styled-components';
import useEventListener from '../../hooks/useEventListener';

export type Props = {
  menu: ReactNode;
  menuWidth?: number;
  appBarTitle: string;
  children: ReactNode;
  appBarLeftContent?: (title: string) => ReactNode;
  appBarRightContent?: ReactNode;
  isLoading?: boolean;
  appBarZIndex?: number;
  contentBackgroundColor?: string;
  thresholdWidth?: number;
};

function getSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

const AppBarWithMenuTemplate = ({
  menu,
  menuWidth = 300,
  appBarTitle,
  children,
  appBarLeftContent,
  appBarRightContent,
  isLoading,
  appBarZIndex = 1000,
  contentBackgroundColor,
  thresholdWidth = 1000,
}: Props) => {
  const [windowSize, setWindowSize] = useState(getSize());
  useEventListener('resize', () => setWindowSize(getSize()));
  const isDesktopView = windowSize.width >= thresholdWidth;
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <Page contentBackgroundColor={contentBackgroundColor}>
      {isDesktopView && <StickyMenu menuWidth={menuWidth}>{menu}</StickyMenu>}
      {!isDesktopView && isMenuOpen && (
        <FloatingMenuMenu
          isOpen={isMenuOpen}
          menuClose={() => setMenuOpen(false)}
          menuWidth={menuWidth}
        >
          {menu}
        </FloatingMenuMenu>
      )}

      <RightSide isDesktopView={isDesktopView} menuWidth={menuWidth}>
        <Sticky zIndex={appBarZIndex}>
          <AppBarSimple
            title={appBarTitle}
            showMenu={!isDesktopView}
            isLoading={isLoading}
            leftContent={appBarLeftContent}
            rightContent={appBarRightContent}
            onMenuClick={() => setMenuOpen(true)}
          />
        </Sticky>
        {children}
      </RightSide>
    </Page>
  );
};

export default AppBarWithMenuTemplate;

const FloatingMenuMenu = ({
  children,
  menuClose,
  menuWidth,
}: {
  children: ReactNode;
  menuClose(): void;
  isOpen: boolean;
  menuWidth: number;
}) => (
  <BackDrop onClick={menuClose}>
    <FloatingMenuDiv menuWidth={menuWidth}>{children}</FloatingMenuDiv>
  </BackDrop>
);

const StickyMenu = styled.div<{ menuWidth: number }>`
  position: fixed;
  top: 0;
  height: 100vh;
  width: ${(p) => `${p.menuWidth}px`};
`;
const Page = styled.div<{ contentBackgroundColor?: string }>`
  background-color: ${(p) => p.contentBackgroundColor ?? 'inherit'};
`;

const RightSide = styled.div<{ isDesktopView: boolean; menuWidth: number }>`
  flex: 1;
  margin-left: ${(p) => (p.isDesktopView ? `${p.menuWidth}px` : '0')};
`;
const Sticky = styled.div<{ zIndex?: number }>`
  position: sticky;
  top: 0;
  z-index: ${(p) => p.zIndex ?? 200};
`;

const FloatingMenuDiv = styled.div<{ menuWidth: number }>`
  position: fixed;
  top: 0;
  height: 100vh;
  width: ${(p) => `${p.menuWidth}px`};
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

const BackDrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  position: fixed;
  justify-content: center;
  background-color: #00000066;
  -webkit-tap-highlight-color: transparent;
`;
