import React from 'react';
import { createSvgIcon } from './IconCreator';

export const MenuIcon = createSvgIcon({
  content: <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>,
});

export const SearchIcon = createSvgIcon({
  content: (
    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
  ),
});

export const CancelIcon = createSvgIcon({
  content: (
    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
  ),
});

export const NAIT = createSvgIcon<true>({
  defaultProps: { x: '0px', y: '0px', viewBox: '0 0 159 213.43' },
  content: (
    <g id="surface1">
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(11.764526%,21.568298%,51.373291%)',
        }}
        d="M 79.789062 208.324219 L 79.464844 208.398438 L 79.214844 208.324219 C 30.523438 192.929688 4.800781 161.699219 4.878906 118.039062 L 4.878906 4.800781 L 154.199219 4.800781 L 154.203125 118.039062 C 154.203125 161.707031 128.480469 192.929688 79.789062 208.324219 Z M 158.996094 0 L 0 0 L 0 118.039062 C 0 164.007812 26.890625 196.8125 77.761719 212.910156 C 77.789062 212.910156 79.464844 213.429688 79.464844 213.429688 L 81.21875 212.910156 C 132.109375 196.820312 158.996094 164.015625 158.996094 118.039062 L 158.996094 0 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(100%,100%,95.68634%)',
        }}
        d="M 149.394531 116.65625 C 149.394531 158.503906 125.746094 188.363281 80.976562 203.015625 L 79.464844 203.542969 C 79.464844 203.542969 77.964844 203.015625 77.933594 203.015625 C 33.1875 188.355469 9.535156 158.496094 9.535156 116.65625 L 9.535156 9.585938 L 149.394531 9.585938 Z M 4.878906 4.800781 L 4.878906 118.039062 C 4.800781 161.699219 30.523438 192.929688 79.214844 208.324219 L 79.464844 208.398438 L 79.789062 208.324219 C 128.480469 192.929688 154.203125 161.707031 154.203125 118.039062 L 154.199219 4.800781 L 4.878906 4.800781 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(39.607239%,62.353516%,78.823853%)',
        }}
        d="M 117.285156 106.777344 L 117.273438 106.78125 C 117.273438 106.785156 117.277344 106.785156 117.28125 106.789062 L 117.285156 106.777344 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(39.607239%,62.353516%,78.823853%)',
        }}
        d="M 62.808594 75.160156 L 62.792969 75.179688 C 62.800781 75.183594 62.804688 75.183594 62.808594 75.183594 L 62.808594 75.160156 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(39.607239%,62.353516%,78.823853%)',
        }}
        d="M 48.699219 185.957031 L 48.714844 185.949219 C 48.707031 185.945312 48.699219 185.9375 48.691406 185.9375 L 48.699219 185.957031 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(39.607239%,62.353516%,78.823853%)',
        }}
        d="M 117.273438 106.78125 C 102.5 91.789062 83.773438 80.582031 62.808594 75.183594 L 70.578125 133.617188 L 117.273438 106.78125 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(58.039856%,72.549438%,84.706116%)',
        }}
        d="M 37.738281 152.496094 L 70.578125 133.617188 L 62.808594 75.183594 C 62.804688 75.183594 62.800781 75.183594 62.792969 75.179688 L 30.066406 129.074219 L 37.738281 152.496094 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(39.607239%,62.353516%,78.823853%)',
        }}
        d="M 62.792969 75.179688 C 58.492188 74.074219 54.105469 73.195312 49.632812 72.597656 C 36.566406 70.855469 23.917969 71.496094 12.085938 74.191406 L 12.085938 74.199219 L 30.066406 129.074219 L 62.792969 75.179688 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(58.039856%,72.549438%,84.706116%)',
        }}
        d="M 75.941406 173.949219 L 98.433594 164.03125 L 117.28125 106.789062 C 117.277344 106.785156 117.273438 106.785156 117.273438 106.78125 L 70.578125 133.617188 L 75.941406 173.949219 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(39.607239%,62.353516%,78.823853%)',
        }}
        d="M 142.535156 144.585938 C 142.535156 144.582031 142.535156 144.582031 142.539062 144.578125 C 136.632812 130.523438 127.992188 117.664062 117.28125 106.789062 L 98.433594 164.03125 L 142.535156 144.585938 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(79.216003%,85.882568%,91.764832%)',
        }}
        d="M 48.714844 185.949219 C 57.398438 191.769531 67.566406 196.617188 79.207031 200.421875 L 79.46875 200.511719 L 79.472656 200.511719 L 75.941406 173.949219 L 48.714844 185.949219 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(70.196533%,80.000305%,88.627625%)',
        }}
        d="M 75.941406 173.949219 L 79.472656 200.511719 L 79.726562 200.421875 C 82.34375 199.566406 84.878906 198.652344 87.347656 197.691406 L 98.433594 164.03125 L 75.941406 173.949219 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(58.039856%,72.549438%,84.706116%)',
        }}
        d="M 142.535156 144.585938 L 98.433594 164.03125 L 87.347656 197.691406 C 115.953125 186.582031 134.628906 168.539062 142.535156 144.585938 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(70.196533%,80.000305%,88.627625%)',
        }}
        d="M 30.066406 129.074219 L 18.003906 148.9375 C 19.609375 152.976562 21.535156 156.839844 23.785156 160.511719 L 37.738281 152.496094 L 30.066406 129.074219 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(70.196533%,80.000305%,88.627625%)',
        }}
        d="M 70.578125 133.617188 L 37.738281 152.496094 L 48.691406 185.9375 C 48.699219 185.9375 48.707031 185.945312 48.714844 185.949219 L 75.941406 173.949219 L 70.578125 133.617188 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(58.039856%,72.549438%,84.706116%)',
        }}
        d="M 12.085938 74.199219 L 12.085938 116.273438 C 12.085938 128.183594 14.089844 139.089844 18.003906 148.9375 L 30.066406 129.074219 L 12.085938 74.199219 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(79.216003%,85.882568%,91.764832%)',
        }}
        d="M 23.785156 160.511719 C 29.816406 170.359375 38.148438 178.867188 48.691406 185.9375 L 37.738281 152.496094 L 23.785156 160.511719 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(11.764526%,21.568298%,51.373291%)',
        }}
        d="M 79.726562 200.421875 L 79.46875 200.511719 L 79.207031 200.421875 C 35.292969 186.066406 12.085938 156.96875 12.085938 116.273438 L 12.085938 74.191406 C 23.917969 71.496094 36.566406 70.855469 49.632812 72.597656 C 91.742188 78.234375 126.816406 107.160156 142.539062 144.578125 C 133.953125 170.605469 112.664062 189.65625 79.726562 200.421875 Z M 9.535156 9.585938 L 9.535156 116.65625 C 9.535156 158.496094 33.1875 188.355469 77.933594 203.015625 C 77.964844 203.015625 79.464844 203.542969 79.464844 203.542969 L 80.976562 203.015625 C 125.746094 188.363281 149.394531 158.503906 149.394531 116.65625 L 149.394531 9.585938 L 9.535156 9.585938 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(100%,100%,95.68634%)',
        }}
        d="M 92.964844 21.4375 C 92.972656 22.113281 93.445312 22.28125 94.199219 22.480469 C 96.855469 23.085938 97.128906 24.070312 97.128906 26.171875 L 97.128906 49.082031 C 97.128906 51.175781 96.855469 52.167969 94.199219 52.777344 C 93.445312 52.976562 92.972656 53.140625 92.964844 53.808594 L 92.964844 54.945312 L 106.007812 54.945312 L 106.007812 53.808594 C 106.003906 53.132812 105.519531 52.976562 104.777344 52.777344 C 102.113281 52.167969 101.839844 51.175781 101.839844 49.082031 L 101.839844 26.171875 C 101.839844 24.070312 102.113281 23.085938 104.777344 22.480469 C 105.519531 22.28125 106.003906 22.125 106.007812 21.4375 L 106.007812 20.300781 L 92.964844 20.300781 L 92.964844 21.4375 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(100%,100%,95.68634%)',
        }}
        d="M 27.140625 27.371094 L 27.140625 49.0625 C 27.140625 51.167969 27.371094 52.148438 29.847656 52.761719 C 30.539062 52.945312 30.976562 53.113281 30.988281 53.796875 L 30.988281 54.929688 L 20.253906 54.929688 L 20.253906 53.796875 C 20.265625 53.113281 20.710938 52.945312 21.402344 52.761719 C 23.871094 52.148438 24.113281 51.167969 24.113281 49.0625 L 24.113281 26.171875 C 24.113281 24.070312 23.871094 23.085938 21.402344 22.464844 C 20.710938 22.28125 20.265625 22.113281 20.253906 21.4375 L 20.253906 20.300781 L 26.789062 20.300781 C 28.097656 20.328125 28.347656 20.753906 28.96875 21.519531 C 28.96875 21.519531 48.050781 45.183594 48.144531 45.292969 C 48.160156 45.152344 48.144531 26.171875 48.144531 26.171875 C 48.144531 24.070312 47.917969 23.085938 45.441406 22.464844 C 44.742188 22.28125 44.300781 22.113281 44.289062 21.4375 L 44.289062 20.300781 L 55.082031 20.300781 L 55.082031 21.4375 C 55.078125 22.113281 54.625 22.28125 53.933594 22.464844 C 51.46875 23.085938 51.21875 24.070312 51.21875 26.171875 L 51.21875 54.929688 L 50.628906 54.929688 C 49.730469 54.929688 49.21875 54.25 48.535156 53.363281 L 27.140625 27.371094 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(100%,100%,95.68634%)',
        }}
        d="M 66.660156 40.46875 C 66.914062 39.847656 72.019531 27.859375 72.03125 27.859375 C 72.03125 27.859375 76.652344 39.828125 76.925781 40.46875 Z M 89.027344 52.777344 C 87.371094 52.425781 86.523438 51.167969 85.589844 49.148438 C 85.589844 49.148438 73.851562 19.699219 73.820312 19.644531 L 72.480469 19.734375 C 71.921875 19.808594 71.367188 19.847656 70.597656 21.375 C 70.585938 21.394531 59.605469 49.167969 59.605469 49.167969 C 58.660156 51.175781 56.890625 52.425781 55.21875 52.777344 C 54.472656 52.917969 53.988281 53.121094 53.988281 53.808594 L 53.988281 54.929688 L 66.660156 54.929688 L 66.660156 53.808594 C 66.660156 53.121094 65.820312 53.027344 65.09375 52.777344 C 62.460938 51.84375 62.765625 50.472656 63.199219 49.074219 C 63.199219 49.074219 65.550781 43.378906 65.628906 43.199219 L 77.929688 43.199219 C 78.007812 43.394531 80.464844 49.101562 80.464844 49.101562 C 80.910156 50.492188 81.855469 51.84375 79.21875 52.777344 C 78.5 53.027344 77.652344 53.121094 77.652344 53.808594 L 77.652344 54.929688 L 90.269531 54.929688 L 90.269531 53.808594 C 90.265625 53.121094 89.777344 52.917969 89.027344 52.777344 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fillOpacity: 1,
          fill: 'rgb(100%,100%,95.68634%)',
        }}
        d="M 108.867188 20.300781 L 108.867188 28.566406 L 109.804688 28.566406 C 110.441406 28.554688 110.644531 28.109375 110.882812 27.332031 C 111.667969 24.570312 112.835938 23.792969 114.804688 23.835938 L 121.144531 23.835938 L 121.152344 49.0625 C 121.152344 51.167969 120.402344 52.148438 117.753906 52.761719 C 117.011719 52.945312 116.535156 53.113281 116.519531 53.796875 L 116.519531 54.929688 L 130.769531 54.929688 L 130.769531 53.796875 C 130.757812 53.113281 130.273438 52.945312 129.535156 52.761719 C 126.875 52.148438 126.128906 51.167969 126.128906 49.0625 L 126.136719 23.835938 L 132.472656 23.835938 C 134.441406 23.792969 135.605469 24.570312 136.394531 27.332031 C 136.632812 28.109375 136.828125 28.554688 137.476562 28.566406 L 138.484375 28.566406 L 138.484375 20.300781 L 108.867188 20.300781 "
      />
    </g>
  ),
});
