
import React from 'react';
import styled from "styled-components";
export type Props = {
    onClick?():void;
    text:string;
};

const BackButton = (props: Props) => {
    return <Container onClick={props.onClick}>{arrowBackIcon}<span>{props.text}</span></Container>;
}

const Container = styled.button`
  padding: 6px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  position: relative;
  align-items: center;
  user-select: none;
  color:#fff;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  & > span {
    font-family: "Whitney","Helvetica Neue",Arial,sans-serif;
    font-weight: 500;
    font-size: 1.125rem;
    letter-spacing: 0.07em;
    padding-left: 1em;
    border-left: 1px solid #270c0cde;
    border-left-width: 0;
    line-height: 2rem;
    margin-left: 0.125em;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
const  arrowBackIcon  = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30px"
      viewBox="0 0 24 24"
      width="30px"
      fill="currentColor"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
    </svg>
  );
  
export default BackButton;