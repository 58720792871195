import React from 'react';
import AppBar from '../AppBar';
import styled from 'styled-components';

export type Props = {
  appBarRightContent?: JSX.Element;
  appBarHeadingText?: string;
  body?: JSX.Element | string;
  responsiveBody?: boolean;
  onAppBarHeadingClick?(): void;
  isLoading?: boolean;
  appBarIsBackButton?: boolean;
};

const MainPageTemplate = ({
  body,
  responsiveBody = true,
  onAppBarHeadingClick,
  isLoading = false,
  appBarRightContent,
  appBarHeadingText,
  appBarIsBackButton,
}: Props) => {
  return (
    <>
      <AppBar
        headingText={appBarHeadingText}
        rightContent={appBarRightContent}
        onHeadingClick={onAppBarHeadingClick}
        isLoading={isLoading}
        isBackButton={appBarIsBackButton}
      />
      <Page>
        {responsiveBody && <ResponsiveBody>{body}</ResponsiveBody>}
        {!responsiveBody && <NonResponsiveBody>{body}</NonResponsiveBody>}
      </Page>
    </>
  );
};

const Page = styled.div`
  grid-column: 1;
  grid-row: 1;
`;

export const ResponsiveBody = styled.div<{ maxWidth?: 600 | 800 | 1000 }>`
  margin: 90px 0;
  @media (min-width: 780px) {
    width: ${(p) => (p.maxWidth && p.maxWidth < 800 ? p.maxWidth : 800)}px;
    margin: 150px auto;
  }
  @media (min-width: 1100px) {
    width: ${(p) => (p.maxWidth ? p.maxWidth : 1000)}px;
    margin: 150px auto;
  }
`;

export const NonResponsiveBody = styled.div<{ maxWidth?: 600 | 800 | 1000 }>`
  margin: 100px 0;
`;

export default MainPageTemplate;
