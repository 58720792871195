import { useState } from "react";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";

function wrapInDispatch(action: any, fn: any, dispatch: Dispatch<any>) {
  var ogFn = action[fn];
  action[fn] = function () {
    try {
      dispatch(ogFn.apply(action, arguments));
    } catch (err) {
      // doh.
    }
  };
}

function dispatchify<T>(actions: T, dispatch: Dispatch<any>): T {
  for (var fn in actions) {
    var type = typeof actions[fn];
    if (type === "function") {
      wrapInDispatch(actions, fn, dispatch);
    } else if (type === "object") {
      dispatchify(actions[fn], dispatch);
    }
  }

  return actions;
}

export function useActions<ActionTypes>(reducerActions: ActionTypes) {
  const [actions] = useState<ActionTypes>(dispatchify<ActionTypes>(reducerActions, useDispatch()));

  return actions;
}

export function useReduxState<TSelected = unknown>(selector: (state: any) => TSelected): TSelected {
  const state = useSelector(selector) as TSelected;

  return state;
};